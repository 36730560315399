/* eslint-disable */
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { usePriceCakeBusd } from 'state/hooks'
import React, { useCallback, useState } from 'react'
import { useWallet } from '@binance-chain/bsc-use-wallet'
import { useWalletModal } from '@pancakeswap-libs/uikit'
import classnames from "classnames";


const HeaderContainer = styled.div`
  padding: 8px 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1 {
    font-size: 16px;
    text-transform: uppercase;
    color: #fff;
  }
  .logoContainer {
    margin-right: 30px;
  }

  .dropbtn {
    cursor:pointer;xzxzx
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(255,255,255,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: white;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #454545;}
  
  .dropdown:hover .dropdown-content {display: block;}

  
  .header_box {
    padding: 30px 5px 30px 5px;
    border-top: 5px solid rgba(255, 255, 255, 0);
  }

  .header_box.active {
    border-top: 5px solid #00C681;
  }

  .nav_container {
    ul {
      list-style: none;
      li {
        float: left;
        margin: 0px 10px;

        

        .header_link {
            color: #fff;
            font-family: 'IBM Plex Mono', monospace;
            font-size: 18px;
            text-transform: uppercase;
            font-style: normal;
        }

        .header_link.active {
            color: #00C681;
            font-style: bold;
            font-weight: 800;
        }
      }
    }
  }
  .bottomHeader {
    padding: 8px 0px;
    background: red;
  }
`
const BottomHeader = styled.div`
  font-family: 'IBM Plex Mono Italic', monospace;
  font-size: 18px;
  text-align: center;
  padding: 18px;
  font-style: italic;
  background: rgb(241, 104, 243);
  background: linear-gradient(137deg, rgb(0, 198, 129) 0%, rgb(0, 198, 129) 100%);
`
const ConnectButton = styled.button`
  padding: 12px 22px;
  border: none;
  border: 1px solid #00C681;
  border-radius: 4px;
  background: #0a2027;
  cursor: pointer;
  font-size: 12px;
  color: #fff;
  display: flex;
`
const EggContainer = styled.div`
  display: flex;
  padding: 0px 10px;
  border: 1px solid #d00dd3;
  flex-direction: row;
  margin-right: 5px;
  border-radius: 4px;
  align-items: center;
  .count {
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    margin-left: 10px;
  }
`
export interface HeaderProps {
  lpLabel?: string
}

function Mainheader(props: HeaderProps) {
  const [activePage, setActivePage] = useState('farms')
  const cakePriceUsd = usePriceCakeBusd()
  const location = useLocation();
  const { connect, reset, account } = useWallet()
  const { onPresentConnectModal } = useWalletModal(connect, reset)

  return (
    <div>
      <HeaderContainer>
        <div style={{ display: 'flex' }}>
          <div className="logoContainer header_box">
            <h1 style={{ fontSize: "x-large", marginTop: '-5px' }}>
              <Link to="/">
                ENCANTO
              </Link>
            </h1>
          </div>
          <div className="nav_container">
            <ul>
              <li>
                <div className={classnames("header_box", { active: location.pathname === '/Trade' })}>
                  <Link
                    to={{ pathname: "https://forteswap.xyz/" }}
                    target="_blank"
                    className={classnames("header_link", { active: location.pathname === '/Trade' })}
                  >
                    Trade
                  </Link>
                </div>
              </li>
              <li>
                <div className={classnames("header_box", { active: location.pathname.includes('/Farms') })}>
                  <Link to="/Farms" className={classnames("header_link", { active: location.pathname.includes('/Farms') })}>
                    Farms
                  </Link>
                </div>
              </li>
              {/* <li>
                <div className={classnames("header_box", { active: location.pathname.includes('/Pools') })}>
                <Link to="/Pools" className={classnames("header_link", { active: location.pathname.includes('/Pools') })}>
                  Pools
                </Link>
                </div>
              </li> */}
              <li>
            
                <div className="dropdown header_box">
                  <div className="header_link dropbtn">More</div>
                  <div className="dropdown-content">
                    <a href="https://twitter.com/EncantoWeTrust">Twitter</a>
                    <a href="https://t.me/+w0rOurOauIkzNjQ5">Telegram</a>
                    <a href="https://github.com/orgs/Encanto-farms/repositories">GitHub</a>
                    <a href="https://encanto.gitbook.io/encanto/">Docs</a>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div style={{ display: 'flex' }}>
          <EggContainer>
            <div className="egg">
              <img src="/images/tokens/ENC.png" alt="bliss logo" width="22px" height="22px" />
            </div>
            <div className="count">${cakePriceUsd.toFixed(2)}</div>
          </EggContainer>
          {!account ? <ConnectButton onClick={onPresentConnectModal}>Connect Wallet</ConnectButton> : <ConnectButton onClick={onPresentConnectModal}>Connected</ConnectButton>}
        </div>
      </HeaderContainer>
      <BottomHeader>Rewards are live</BottomHeader>
    </div>
  )
}

export default Mainheader
