import { PoolConfig, QuoteToken, PoolCategory } from './types'

const pools: PoolConfig[] = [
  {
    sousId: 0,
    tokenName: 'ENC',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x373A8cD983948c5c20759dFae763D3bb7790F383',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      7700: '0x373A8cD983948c5c20759dFae763D3bb7790F383',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 2,
    tokenName: 'USDC',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      7700: '0xf2f9aa168015519ebc083a40dfdd9e2ab380d1cc',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 3,
    tokenName: 'USDT',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0xd567B3d7B8FE3C79a1AD8dA978812cfC4Fa05e75',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      7700: '0xf2f9aa168015519ebc083a40dfdd9e2ab380d1cc',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 4,
    tokenName: 'ATOM',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0xecEEEfCEE421D8062EF8d6b4D814efe4dc898265',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      7700: '0xf2f9aa168015519ebc083a40dfdd9e2ab380d1cc',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 5,
    tokenName: 'NOTE',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      7700: '0xf2f9aa168015519ebc083a40dfdd9e2ab380d1cc',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
  {
    sousId: 6,
    tokenName: 'wCANTO',
    stakingTokenName: QuoteToken.CAKE,
    stakingTokenAddress: '0x826551890Dc65655a0Aceca109aB11AbDbD7a07B',
    contractAddress: {
      97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
      7700: '0xf2f9aa168015519ebc083a40dfdd9e2ab380d1cc',
    },
    poolCategory: PoolCategory.CORE,
    projectLink: 'https://pancakeswap.finance/',
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    tokenDecimals: 18,
  },
]

export default pools
