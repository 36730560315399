export default {
  cake: { // enc
    7700: '0x373A8cD983948c5c20759dFae763D3bb7790F383',
    97: '',
  },
  masterChef: {
    7700: '0x6c0894671aE3352EB36d90EC90e2186eb2a6fDfA',
    97: '',
  },
  wbnb: { // wcanto
    7700: '0x826551890Dc65655a0Aceca109aB11AbDbD7a07B',
    97: '',
  },
  lottery: {
    7700: '',
    97: '',
  },
  lotteryNFT: {
    7700: '',
    97: '',
  },
  mulltiCall: {
    7700: '0xd4F8569657b4a3C77514fE42C2E8d6F60c8DA05e',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: { // usdc
    7700: '0x80b5a32E4F032B2a058b4F29EC95EEfEEB87aDcd',
    97: '',
  },
  note: { // note
    7700: '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503',
    97: '',
  },
  atom: { // atom
    7700: '0xecEEEfCEE421D8062EF8d6b4D814efe4dc898265',
    97: '',
  },
}
