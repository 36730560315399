import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'ENC-NOTE LP',
    lpAddresses: {
      97: '',
      7700: '0x58F30E190F52c91e28761FCE4499c3640E1c4D3E',
    },
    tokenSymbol: 'ENC',
    firstTokenImage: 'ENC',
    secondTokenSymbol: 'NOTE',
    tokenAddresses: {
      97: '',
      7700: '0x373A8cD983948c5c20759dFae763D3bb7790F383',
    },
    quoteTokenSymbol: QuoteToken.NOTE,
    quoteTokenAdresses: contracts.note,
  },
  {
    pid: 1,
    risk: 2,
    lpSymbol: 'ENC-WCANTO LP',
    firstTokenImage: 'ENC',
    secondTokenSymbol: 'WCANTO',
    lpAddresses: {
      97: '',
      7700: '0x992BDeBFe157256D848a7C221FB6A775cA85CBD2',
    },
    tokenSymbol: 'ENC',
    tokenAddresses: {
      97: '',
      7700: '0x373A8cD983948c5c20759dFae763D3bb7790F383',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 1,
    lpSymbol: 'WCANTO-NOTE LP',
    firstTokenImage: 'WCANTO',
    secondTokenSymbol: 'NOTE',
    lpAddresses: {
      97: '',
      7700: '0x5118e2f29657A701261e401e5A25d3e814fd7Cc1',
    },
    tokenSymbol: 'WCANTO',
    tokenAddresses: {
      97: '',
      7700: '0x826551890Dc65655a0Aceca109aB11AbDbD7a07B',
    },
    quoteTokenSymbol: QuoteToken.NOTE,
    quoteTokenAdresses: contracts.note,
  },
  {
    pid: 3,
    risk: 2,
    lpSymbol: 'ETH-NOTE LP',
    firstTokenImage: 'ETH',
    secondTokenSymbol: 'NOTE',
    lpAddresses: {
      97: '',
      7700: '0xd5A5FC5f0d0757a5e074Ef3F6Bb40B15d1A8A59c',
    },
    tokenSymbol: 'ETH',
    tokenAddresses: {
      97: '',
      7700: '0x5FD55A1B9FC24967C4dB09C513C3BA0DFa7FF687',
    },
    quoteTokenSymbol: QuoteToken.NOTE,
    quoteTokenAdresses: contracts.note,
  },
  {
    pid: 4,
    risk: 2,
    firstTokenImage: 'ATOM',
    secondTokenSymbol: 'NOTE',
    lpSymbol: 'ATOM-NOTE LP',
    lpAddresses: {
      97: '',
      7700: '0x4f2Ff38F5F2DeBb75461A05F992E5f2478dE6793',
    },
    tokenSymbol: 'ATOM',
    tokenAddresses: {
      97: '',
      7700: '0xecEEEfCEE421D8062EF8d6b4D814efe4dc898265',
    },
    quoteTokenSymbol: QuoteToken.NOTE,
    quoteTokenAdresses: contracts.note,
  },
  {
    pid: 5,
    risk: 2,
    firstTokenImage: 'cINU',
    secondTokenSymbol: 'WCANTO',
    lpSymbol: 'cINU-WCANTO LP',
    lpAddresses: {
      97: '',
      7700: '0x42A515C472b3B953beb8ab68aDD27f4bA3792451',
    },
    tokenSymbol: 'cINU',
    tokenAddresses: {
      97: '',
      7700: '0x7264610A66EcA758A8ce95CF11Ff5741E1fd0455',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    risk: 2,
    lpSymbol: 'NOTE-USDC LP',
    firstTokenImage: 'NOTE',
    secondTokenSymbol: 'USDC',
    lpAddresses: {
      97: '',
      7700: '0x2Bd907F3B8b1dE8B9D9aF90FD3cB1995d5A8BD80',
    },
    tokenSymbol: 'NOTE',
    tokenAddresses: {
      97: '',
      7700: '0x4e71A2E537B7f9D9413D3991D37958c0b5e1e503',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 8,
    risk: 2,
    lpSymbol: 'RAB-ATOM LP',
    firstTokenImage: 'RAB',
    secondTokenSymbol: 'ATOM',
    lpAddresses: {
      97: '',
      7700: '0x100fc12698F363c31cd24dd53e5711Bef658A3a6',
    },
    tokenSymbol: 'RAB',
    tokenAddresses: {
      97: '',
      7700: '0x241f6A1Ea972EEDB05F76e25179347719e4e8B72',
    },
    quoteTokenSymbol: QuoteToken.ATOM,
    quoteTokenAdresses: contracts.atom,
  }
]

export default farms
